<template>
  <div class="container" id="container">
    <div class="header">
      <a href="javascript:;" class="back" @click="back">返回</a>
      <h1>旅游路线</h1>
    </div>
    <!-- 轮播 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in slider" :key="index">
        <div v-if="item.interlinkage" class="box" @click="toDetail(item)">
          <h1>{{item.description}}</h1>
          <van-image fit="fill" :src="item.imgUrl" class="recr_swiper_item_img">
            <template v-slot:error>加载失败</template>
          </van-image>
        </div>
        <div v-else class="box">
          <h1>{{item.description}}</h1>
          <van-image fit="fill" :src="item.imgUrl" class="recr_swiper_item_img">
            <template v-slot:error>加载失败</template>
          </van-image>
        </div>
      </van-swipe-item>
    </van-swipe>
    <a href="javascript:;" @click="showadd = true" class="addbtn"><img src="../../hotel/img/icon-addDestination.png" alt="添加目的地" /></a>
    <div class="blockname">
      <span><i class="l"></i>全部路线<i class="r"></i></span>
    </div>
    <div class="list">
      <a href="javascript:;" @click="detail(item.groupId)" v-for="(item, index) in allLine" :key="index">
        <div class="info">
          <h1>{{item.title}}</h1>
          <li>{{item.areaNames}} | {{item.playPlacesNumber}}个观光点</li>
          <span>开始阅读</span>
        </div>
        <!-- <div class="img" :style = "{background: 'url('+ item.cover +') no-repeat center center/cover'}"> -->
        <div class="img">
          <img :src="item.cover" :alt="item.title" />
        </div>
      </a>
    </div>
    <div class="loading" v-show="loading"> <img src="../../hotel/img/loading.png" alt="正在加载" />正在加载 </div>
    <div class="nomore" v-show="nomore">没有更多了~</div>
    <div class="addbox" v-show="showadd">
      <div class="bg" @click="showadd = false"></div>
      <div class="box">
        <div class="title"><a href="javascript:;" @click="showadd = false">关闭</a>添加目的地</div>
        <div class="items">
          <div class="item">
            <div class="itemname">游玩人数</div>
            <div class="iteminfo persons">
              <img src="../../hotel/img/icon-reduce.png" alt="减少" @click="reduce()" />
              <input type="text" placeholder="" v-model="persons" @change="isNumber()" />
              <img src="../../hotel/img/icon-add.png" alt="增加" @click="increase()" />
            </div>
          </div>
          <div class="item">
            <div class="itemname">是否有老人</div>
            <div class="iteminfo">
              <a href="javascript:;" :class="{'checked' : hasAged}" @click="hasAged = true"><i></i>有</a>
              <a href="javascript:;" :class="{'checked' : !hasAged}" @click="hasAged = false"><i></i>无</a>
            </div>
          </div>
          <div class="item">
            <div class="itemname">是否有小孩</div>
            <div class="iteminfo">
              <a href="javascript:;" :class="{'checked' : hasChild}" @click="hasChild = true"><i></i>有</a>
              <a href="javascript:;" :class="{'checked' : !hasChild}" @click="hasChild = false"><i></i>无</a>
            </div>
          </div>
          <div class="row">游玩天数</div>
          <div class="days">
            <span v-for="(item, index) in dayList" :key="index" :class="{'checked': item == days}" @click="checkDays(item)">{{item.name}}</span>
          </div>
          <div class="row">选择目的地 <a href="javascript:;" v-show="regions.length < 3" class="add" @click="showRegionList()">添加<img src="../../hotel/img/icon-addnew.png" alt="添加" /></a><a href="javascript:;" v-show="regions.length >= 3" class="clearall" @click="regions = []">全部清除</a></div>
          <div class="scenic">
            <span v-for="(item, index) in regions" :key="index">{{item.name}}<img src="../../hotel/img/icon-delete.png" alt="删除" @click="deleteRegion(item)" /></span>
          </div>
        </div>
        <div class="btn"><a href="javascript:;" @click="lineList()">立即推荐</a></div>
      </div>
    </div>
    <div class="regionbox" v-show="showRegion">
      <div class="title">
        <a href="javascript:;" @click="showRegion = false" class="close">关闭</a>选择目的地<a href="javascript:;" @click="lsRegions = []" class="clear">清除</a>
      </div>
      <div class="regions">
        <div class="text">热门景点</div>
        <div class="regionlist">
          <span v-for="(item, index) in hotRegionList" :key="index" @click="checkeRegionlist(item)" :class="{'checked': lsRegions.includes(item)}">{{item.name}}<img src="../../hotel/img/icon-delete.png" alt="删除" /></span>
        </div>
        <div class="text">全部景点</div>
        <div class="regionlist">
          <span v-for="(item, index) in regionList" :key="index" @click="checkeRegionlist(item)" :class="{'checked': lsRegions.includes(item)}">{{item.name}}<img src="../../hotel/img/icon-delete.png" alt="删除" /></span>
        </div>
      </div>
      <div class="btn"><a href="javascript:;" @click="save()">确定</a></div>
    </div>
  </div>
</template>
<script>
  import { Swipe, SwipeItem, Image, Toast} from 'vant'
  export default {
    name: "index",
    components:{
      'van-swipe':Swipe,
      'van-image':Image,
      'van-swipe-item':SwipeItem,
    },
    data() {
      return {
        api: {
          type: "/group/queryGroupType", //路线类型
          banner: "/banner/queryBanner", // 轮播地址
          line: "/group/queryGroupByGroupTypeId", //路线列表
          days: "/enums/list", // 旅游天数枚举
          region: "/group/queryAllAvailableAttractions" // 所有目的地
        },
        groupTypeId: null, // 类型id
        slider: [], // 轮播
        allLine: [], // 全部线路
        pageIndex: 1, // 当前页码
        pageSize: 10, // 每页列表数量
        totalPage: 0, // 总页数
        loading: true, // 正在加载
        showadd: false, // 添加目的地
        showRegion: false, // 显示目的地选择
        regions: [], // 已选景点
        lsRegions: [], // 临时已选景点，未确定时的列表
        hotRegionList: [], // 热门景点
        regionList: [], // 全部景点
        persons: 1, // 游玩人数
        hasAged: false, // 是否有老人
        hasChild: false, // 是否有小孩
        dayList: [], // 天数枚举列表
        days: null, // 游玩天数
        nomore: false // 没有更多了
      }
    },
    created() {
      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
      this.getType() // 获取类型
      this.getDayList() // 获取天数枚举
      this.getRegion() // 获取所有目的地
    },
    mounted() {
      //监听scroll事件
      window.addEventListener("scroll", this.handleScroll, true);
    },
    destroyed() {
      //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      // 获取类型
      getType() {
        let _this = this;
        this.$get({
          url: _this.api.type,
          params: {}
        }).then((rsp) => {
          _this.groupTypeId = rsp[0].groupTypeId
          this.getBanner() // 获取轮播
          this.getAllLine() // 获取全部线路
        })
      },
      // 获取天数枚举
      getDayList() {
        let _this = this;
        this.$get({
          url: _this.api.days,
          params: {
            key: 'group_play_days_type'
          }
        }).then((rsp) => {
          _this.dayList = rsp
          _this.days = rsp[0]
        })
      },
      // 获取所有目的地
      getRegion() {
        let _this = this;
        this.$get({
          url: _this.api.region,
          params: {}
        }).then((rsp) => {
          _this.regionList = rsp
          let hotList = []
          for(let i = 0; i < rsp.length; i++){
            if(rsp[i].popular){
              hotList.push(rsp[i])
            }
          }
          _this.hotRegionList = hotList
        })
      },
      // 获取轮播
      getBanner() {
        let _this = this;
        this.$get({
          url: _this.api.banner,
          params: {
            position: 3
          }
        }).then((rsp) => {
          _this.slider = rsp
        })
      },
      // 查看轮播详情
      toDetail(obj){
        /*this.$router.push({
          path:'/outWindowBlank',
          query:{
            src:encodeURI(obj.interlinkage)
          }
        })*/
        window.location.href = obj.interlinkage
      },
      // 获取全部线路
      getAllLine() {
        let _this = this;
        this.loading = true
        this.$get({
          url: _this.api.line,
          params: {
            groupTypeId: _this.groupTypeId,
            pageIndex: _this.pageIndex,
            pageSize: _this.pageSize,
            sortName: "sequence",
            sortOrder: "asc"
          }
        }).then((rsp) => {
          this.loading = false
          _this.totalPage = Math.ceil(rsp.count / _this.pageSize) //总页数
          _this.allLine = _this.allLine.concat(rsp.data)
          if(_this.pageIndex == _this.totalPage){
            _this.nomore = true
          }
        })
      },
      // 监听滚动条
      handleScroll() {
        let scrollTop = document.getElementById("app").scrollTop;
        let windowHeight = document.getElementById("app").clientHeight;
        let bodyHeight = document.getElementById("container") ? document.getElementById("container").clientHeight - 10 : 0;
        if (scrollTop + windowHeight + 30 >= bodyHeight && !this.loading && this.pageIndex < this.totalPage) {
          this.pageIndex = this.pageIndex + 1
          this.getAllLine()
        }
      },
      // 查看详情
      detail(v) {
        this.$router.push(`/lineTwoDetails/?from=list&groupId=${v}`)
        sessionStorage.setItem('key',1)
      },
      // 后退
      back() {
        this.$router.go(-1)
      },
      // 减少人数
      reduce() {
        if(this.persons > 1){
          this.persons = this.persons - 1
        }
      },
      // 增加人数
      increase() {
        this.persons = this.persons + 1
      },
      // 验证数字
      isNumber() {
        let reg = /^\+?[1-9][0-9]*$/
        if(!reg.test(this.persons)){
          this.persons = 1
          Toast('输入有误！')
        }
      },
      // 选择游玩天数
      checkDays(day) {
        this.days = day
      },
      // 显示目的地选择
      showRegionList() {
        let arr = []
        this.regions.forEach( e => {
          arr.push(e)
        })
        this.lsRegions = arr
        this.showRegion = true
      },
      // 删除某个景点
      deleteRegion(region) {
        this.regions.splice(this.regions.indexOf(region), 1)
      },
      // 选择景点
      checkeRegionlist(region) {
        if(this.lsRegions.includes(region)){
          this.lsRegions.splice(this.lsRegions.indexOf(region), 1)
        }else{
          if(this.lsRegions.length < 3){
            this.lsRegions.push(region)
          }else{
            Toast('最多只能添加3处景点！')
          }
        }
      },
      // 确定目的地选择
      save() {
        let arr = []
        this.lsRegions.forEach( e => {
          arr.push(e)
        })
        this.regions = arr
        this.showRegion = false
      },
      // 查看推荐路线
      lineList(){
        let _this = this;
        let params = JSON.stringify({
          groupTypeId: _this.groupTypeId, // 类型id
          regions: _this.regions, // 已选景点
          persons: _this.persons, // 游玩人数
          hasAged: _this.hasAged, // 是否有老人
          hasChild: _this.hasChild, // 是否有小孩
          days: _this.days // 游玩天数
        })
        this.$router.push({
          path:"/lineList",
          query:{
            params: params
          }
        })
      }
    }
  }
</script>
<style scoped>
  .container{
    background: #f0f0f0;
    padding: 50px 15px 15px 15px;
    font-size: 0;
    color: #fff;
    text-align: center;
    min-height: calc(100vh - 65px);
    box-sizing: border-box;
  }
  .container em, .container li, .container h1{
    font-style: normal;
  }
  .container li{
    list-style-type: none;
  }
  .header{
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    z-index: 10;
    box-shadow: 0 0 5px #ccc;
  }
  .header a{
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background: url(../../hotel/img/black_back.png) no-repeat center center/auto 18px;
    font-size: 0;
  }
  .header h1{
    color: #333;
    font-size: 16px;
    line-height: 50px;
  }
  .my-swipe{
    margin-top: .27rem;
  }
  .my-swipe >>> .van-swipe-item a {
    display: block;
  }
  .my-swipe >>> .van-swipe__indicators {
    bottom: 18px;
    right: 20px;
    left: auto;
    transform: translateX(0);
  }
  .my-swipe >>> .van-swipe__indicators i {
    transition: all 0.2s linear;
    border-radius: 0.16rem;
  }
  .my-swipe >>> .van-swipe__indicators i.van-swipe__indicator--active {
    width: 0.4rem;
  }
  .my-swipe >>> .van-image {
    position: static;
    display: block;
  }
  .addbtn{
    display: block;
    margin: .26rem 0;
  }
  .addbtn img{
    width: 100%;
  }
  .blockname{
    line-height: 0;
    height: 1px;
    background: #333;
    margin: 30px 0 20px 0;
  }
  .blockname span{
    background: #f0f0f0;
    position: relative;
    padding: 0 30px;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  .blockname span i{
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: #333;
    top: 50%;
    margin-top: -2px;
  }
  .blockname span i.l{
    left: 0;
  }
  .blockname span i.r{
    right: 0;
  }
  .list a{
    display: block;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    margin-top: .26rem;
  }
  .list a .info{
    width: 60%;
    height: 95px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
  }
  .list a .info h1{
    height: 40px;
    line-height: 20px;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .list a .info li{
    height: 20px;
    line-height: 20px;
    font-size: 13px;
    color: #888;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 5px 0;
  }
  .list a .info span{
    height: 25px;
    line-height: 22px;
    box-sizing: border-box;
    display: inline-block;
    border: 1px solid #1fdf50;
    border-radius: 3px;
    color: #1fdf50;
    font-size: 14px;
    padding: 0 10px;
    font-weight: bold;
  }
  .list a .img{
    width: 40%;
    height: 95px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
  }
  .list a .img img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .loading {
    font-size: 0.4rem;
    padding: 0.2rem;
    color: #ccc;
    text-align: center;
  }
  .loading img {
    height: 0.4rem;
    vertical-align: middle;
    margin: -0.1rem 0.1rem 0 0;
    animation: loading 0.5s linear infinite;
  }
  /*数据加载动画*/
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  .nomore{
    text-align: center;
    font-size: 12px;
    line-height: 40px;
    color: #888;
  }
  .addbox{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 95;
    left: 0;
    top: 0;
  }
  .addbox .bg{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .75);
    animation: addboxbg 0.25s linear;
    cursor: pointer;
  }
  @keyframes addboxbg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .addbox .box{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    animation: addbox 0.25s linear;
    background: #fff;
    border-radius: 5px 5px 0 0;
    color: #333;
  }
  @keyframes addbox {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: 0;
    }
  }
  .addbox .box .title{
    font-size: 20px;
    text-align: center;
    position: relative;
    height: 50px;
    line-height: 50px;
    color: #333;
    border-bottom: 1px solid #ccc;
  }
  .addbox .box .title a{
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0;
    height: 50px;
    width: 40px;
    background: url(../../hotel/img/icon-close.png) no-repeat center center / 16px auto;
  }
  .addbox .box .items{
    padding: 15px 15px 0 15px;
    box-sizing: border-box;
  }
  .addbox .box .itemname{
    width: 100px;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    text-align: left;
    line-height: 40px;
  }
  .addbox .box .iteminfo{
    width: calc(100% - 100px);
    display: inline-block;
    vertical-align: top;
    text-align: right;
    line-height: 40px;
  }
  .addbox .box .persons img{
    height: 20px;
    vertical-align: middle;
  }
  .addbox .box .persons input{
    height: 20px;
    line-height: 20px;
    border: none;
    font-size: 16px;
    color: #333;
    text-align: center;
    width: 60px;
    vertical-align: middle;
  }
  .addbox .box .iteminfo a{
    font-size: 16px;
    margin-left: 20px;
    color: #333;
  }
  .addbox .box .iteminfo a:first-of-type{
    margin-left: 0;
  }
  .addbox .box .iteminfo a i{
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    background: url(../../hotel/img/icon-radio.png) no-repeat center center/cover;
    margin-right: 5px;
  }
  .addbox .box .iteminfo a.checked i{
    background: url(../../hotel/img/icon-radiochecked.png) no-repeat center center/cover;
  }
  .addbox .box .row{
    font-size: 16px;
    line-height: 40px;
    text-align: left;
  }
  .addbox .box .row a{
    color: #333;
  }
  .addbox .box .days{
    text-align: left;
    padding: 10px 0;
  }
  .addbox .box .days span{
    font-size: 14px;
    border: 1px solid #888;
    color: #888;
    background: #f6f6f6;
    text-align: center;
    padding: 2px 5px;
    border-radius: 3px;
    margin-right: 10px;
    cursor: pointer;
  }
  .addbox .box .days span.checked{
    background: #ddffe9;
    color: #0fce52;
    border-color: #0fce52;
  }
  .addbox .box .row a.add, .addbox .box .row a.clearall{
    width: 80px;
    height: 30px;
    text-align: center;
    display: inline-block;
    border-radius: 20px;
    margin-left: 20px;
    line-height: 30px;
  }
  .addbox .box .row a.add{
    color: #0fce52;
    border: 1px solid #0fce52;
  }
  .addbox .box .row a.clearall{
    color: #ff9001;
    border: 1px solid #ff9001;
  }
  .addbox .box .row a.add img{
    height: 14px;
    margin-left: 5px;
    vertical-align: middle;
  }
  .addbox .box .scenic{
    text-align: left;
  }
  .addbox .box .scenic span{
    background: #ddffe9;
    color: #0fce52;
    /* border: 1px solid #0fce52; */
    padding: 5px 15px;
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
    border-radius: 5px;
    text-align: center;
    position: relative;
    margin: 10px 10px 0 0;
  }
  .addbox .box .scenic span img{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 12px;
    cursor: pointer;
  }
  .addbox .box .btn{
    margin: 20px 0;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .addbox .box .btn a{
    display: block;
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    background: linear-gradient(to right, #7fdc4f, #1ddf50);
    color: #fff;
    font-size: 18px;
  }
  .regionbox{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99;
    left: 0;
    top: 0;
    background: #fff;
    animation: regionbox 0.25s linear;
    box-sizing: border-box;
  }
  @keyframes regionbox {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  .regionbox .title{
    font-size: 20px;
    text-align: center;
    position: relative;
    height: 50px;
    line-height: 50px;
    color: #333;
  }
  .regionbox .title a.close{
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0;
    height: 50px;
    width: 50px;
    background: url(../../hotel/img/icon-close.png) no-repeat center center / 16px auto;
  }
  .regionbox .title a.clear{
    position: absolute;
    right: 15px;
    top: 0;
    font-size: 16px;
    color: #888;
  }
  .regionbox .regions{
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 0 15px;
  }
  .regionbox .regions .text{
    font-size: 18px;
    line-height: 30px;
    text-align: left;
    color: #333;
    margin: 20px 0 10px 0;
  }
  .regionbox .regions .regionlist{
    min-height: 50px;
    text-align: left;
  }
  .regionbox .regions .regionlist span{
    position: relative;
    cursor: pointer;
    background: #f0f0f0;
    color: #333;
    line-height: 40px;
    padding: 0 20px;
    margin: 10px 10px 0 0;
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
    border-radius: 3px;
    text-align: center;
  }
  .regionbox .regions .regionlist span.checked{
    background: #d7f8e9;
    color: #0fce52;
  }
  .regionbox .regions .regionlist span img{
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    height: 15px;
  }
  .regionbox .regions .regionlist span.checked img{
    display: block;
  }
  .regionbox .btn{
    height: 50px;
    background: #0fce52;
  }
  .regionbox .btn a{
    display: block;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
  }
</style>
